exports.components = {
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-index-tsx": () => import("./../../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-privacy-tsx": () => import("./../../../src/pages/privacy.tsx" /* webpackChunkName: "component---src-pages-privacy-tsx" */),
  "component---src-pages-support-tsx": () => import("./../../../src/pages/support.tsx" /* webpackChunkName: "component---src-pages-support-tsx" */),
  "component---src-pages-terms-tsx": () => import("./../../../src/pages/terms.tsx" /* webpackChunkName: "component---src-pages-terms-tsx" */),
  "component---src-pages-yosu-account-delete-account-tsx": () => import("./../../../src/pages/yosu/account/delete-account.tsx" /* webpackChunkName: "component---src-pages-yosu-account-delete-account-tsx" */),
  "component---src-pages-yosu-data-usage-tsx": () => import("./../../../src/pages/yosu/data-usage.tsx" /* webpackChunkName: "component---src-pages-yosu-data-usage-tsx" */),
  "component---src-pages-yosu-privacy-tsx": () => import("./../../../src/pages/yosu/privacy.tsx" /* webpackChunkName: "component---src-pages-yosu-privacy-tsx" */),
  "component---src-pages-yosu-terms-tsx": () => import("./../../../src/pages/yosu/terms.tsx" /* webpackChunkName: "component---src-pages-yosu-terms-tsx" */)
}

